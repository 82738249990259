// This is a manifest file that'll be compiled into application.js, which will include all the files
// listed below.
//
// Any JavaScript/Coffee file within this directory, lib/assets/javascripts, vendor/assets/javascripts,
// or any plugin's vendor/assets/javascripts directory can be referenced here using a relative path.
//
// It's not advisable to add code directly here, but if you do, it'll appear at the bottom of the
// compiled file.
//
// Read Sprockets README (https://github.com/rails/sprockets#sprockets-directives) for details
// about supported directives.
//
//= require jquery
//= require bootstrap
//= require_tree .

 var videoHeight = $('.hero').innerHeight();

 // $(document).scroll(function() {
 //    var documentHeight = $(this).scrollTop();
 //    if (documentHeight > 600) {
 //        $('.smallLogo').css('display' , 'none');
 //        $('.logoColor').css('visibility' , 'visible');
 //    }
 // });
 $(document).ready(function(){


  $(".custom-input").focus(function(){
    $( this ).parent('.form-group').children('hr').addClass('border-show');
    $( this ).blur(function(){
      $( this ).parent('.form-group').children('hr').removeClass('border-show');
    });
  });

   $("#primary-content").css("display", "none");
    $("#primary-content").fadeIn(400); 
    // to fade out before redirect
    $('.menu a').click(function(e){
        redirect = $(this).attr('href');
        e.preventDefault();
        $('#primary-content').fadeOut(400, function(){
            document.location.href = redirect
        });
    });

});

$(window).scroll(function() {    
    var scroll = $(window).scrollTop();

     //>=, not <=
    if (scroll > 700) {
        //clearHeader, not clearheader - caps H
        $("header").addClass("dynamic");
    } else {
        $("header").removeClass("dynamic");
    }
});


//jQuery( document ).ready( function( $ ) {
//
//    $( '#frm' ).on( 'submit', function() {
//
//        //.....
//        //show some spinner etc to indicate operation in progress
//        //.....
//
//        $.post(
//            $( this ).prop( 'action' ),
//            {
//                "_token": $( this ).find( 'input[name=_token]' ).val(),
//                "name": $( '#name' ).val()
//            },
//            function( data ) {
//                console.log(data);
//            },
//            'json'
//        );
//
//        //.....
//        //do anything else you might want to do
//        //.....
//
//        //prevent the form from actually submitting in browser
//        return false;
//    } );
//
//} );